import { Box, Loader } from "@mantine/core";

import classes from "./Loading.module.css";

interface IProps {
    text?: string;
}

function Loading({ text }: IProps): JSX.Element {
    return (
        <Box className={classes.loader}>
            <Loader />
            {text ?? "Loading..."}
        </Box>
    );
}

export default Loading;
