import { useMutation, type UseQueryExecute } from "urql";
import { useForm } from "@mantine/form";
import { Box, Button, Group, TextInput, Title } from "@mantine/core";

import { loginMutation, type LoginMutation, type LoginMutationVariables } from "./queries";

interface IFormValues {
    email: string;
    password: string;
}

interface IProps {
    refetch: UseQueryExecute;
}

function Login({ refetch }: IProps): JSX.Element {
    const [logingResult, executeLogin] = useMutation<LoginMutation, LoginMutationVariables>(loginMutation);

    const form = useForm<IFormValues>({
        initialValues: {
            email: "",
            password: "",
        },
        validate: {
            email: (value) => (value.includes("@") ? null : "Invalid email"),
            password: (value) => (value ? null : "Invalid password"),
        },
    });

    const handleSubmit = async (values: IFormValues) => {
        const result = await executeLogin({ email: values.email, password: values.password });
        if (!result.error) {
            refetch({ requestPolicy: "network-only" });
        }
    };

    return (
        <Box maw={340} mx="auto" p={15}>
            <Title>Login</Title>
            <form onSubmit={form.onSubmit(handleSubmit)}>
                <TextInput withAsterisk label="Email" {...form.getInputProps("email")} />
                <TextInput withAsterisk label="Password" type="password" {...form.getInputProps("password")} />
                <Group justify="flex-end" mt="md">
                    <Button type="submit" disabled={logingResult.fetching}>
                        Submit
                    </Button>
                </Group>
            </form>
        </Box>
    );
}

export default Login;
