import { Button, FileInput, Group, LoadingOverlay, Stack, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { getMime } from "../utils";

export interface IFormValues {
    file: File | null;
}

interface IProps {
    handleSubmit: (values: IFormValues) => void;
    fetching: boolean;
}

const ATTACHABLE_FILES = [getMime("PDF"), getMime("WORD"), getMime("POWERPOINT")];

function AttachFileForm({ handleSubmit, fetching }: IProps): JSX.Element {
    const form = useForm<IFormValues>({
        initialValues: {
            file: null,
        },
        validate: {
            file: (value) => {
                if (!value) {
                    return "Required";
                }
                if (!ATTACHABLE_FILES.includes(value.type)) {
                    return "Unsupported file format";
                }
                return null;
            },
        },
    });

    return (
        <form onSubmit={form.onSubmit(handleSubmit)}>
            <Stack maw={800} mx="auto" my="md">
                <LoadingOverlay visible={fetching} />
                <Text>Here you can upload a word or PDF file to add its contents to the conversations</Text>
                <FileInput
                    label="Select file"
                    withAsterisk
                    accept={ATTACHABLE_FILES.join(",")}
                    {...form.getInputProps("file")}
                />
                <Group justify="flex-end" mt="md">
                    <Button type="submit" disabled={fetching}>
                        Upload
                    </Button>
                </Group>
            </Stack>
        </form>
    );
}

export default AttachFileForm;
