import { Button } from "@mantine/core";
import { IconArrowFork } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";

import { type ThreadForkMutation, type ThreadForkMutationVariables, type ThreadQuery, forkMutation } from "./queries";
import { useMutation } from "urql";
import { useNavigate } from "react-router-dom";

interface IProps {
    thread: NonNullable<ThreadQuery["thread"]>;
}

function ForkButton({ thread }: IProps): JSX.Element {
    const [forkResult, executeFork] = useMutation<ThreadForkMutation, ThreadForkMutationVariables>(forkMutation);
    const navigate = useNavigate();

    const handleClick = async () => {
        const result = await executeFork({ threadId: thread.id });
        if (result.error) {
            notifications.show({
                title: "Failed to fork conversation",
                message: result.error.message,
            });
        } else {
            const thread = result.data!.threadClone;
            navigate(`../${thread.id}`);
        }
    };

    return (
        <Button onClick={handleClick} leftSection={<IconArrowFork />} disabled={forkResult.fetching}>
            Fork thread
        </Button>
    );
}

export default ForkButton;
