import { Box } from "@mantine/core";
import { type FC, type ReactElement, type ReactNode, createContext, useContext } from "react";
import { useQuery } from "urql";
import Loading from "./Loading";
import Login from "./Login";
import { type CurrentUserQuery, currentUserQuery } from "./queries";

interface IUserCurrent {
    id: number;
}

const UserCurrentContext = createContext<IUserCurrent | null>(null);
UserCurrentContext.displayName = "UserCurrentContext";

interface IProps {
    children: ReactNode;
}

export const UserCurrentProvider: FC<IProps> = ({ children }): ReactElement => {
    const [result, refetch] = useQuery<CurrentUserQuery>({ query: currentUserQuery });

    if (result.fetching) {
        return <Loading />;
    }
    if (result.error) {
        if (result.error.message === "[GraphQL] User is not authenticated") {
            return <Login refetch={refetch} />;
        }
        return <Box>Error</Box>;
    }
    if (!result.data?.userCurrent) {
        return <Login refetch={refetch} />;
    }
    return <UserCurrentContext.Provider value={result.data.userCurrent}>{children}</UserCurrentContext.Provider>;
};

export function useUserCurrent(): IUserCurrent | null {
    return useContext(UserCurrentContext);
}
