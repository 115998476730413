import { Button, Group, LoadingOverlay, Stack, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

export interface IFormValues {
    url: string;
}

interface IProps {
    handleSubmit: (values: IFormValues) => void;
    fetching: boolean;
}

function AttachWebpageForm({ handleSubmit, fetching }: IProps): JSX.Element {
    const form = useForm<IFormValues>({
        initialValues: {
            url: "",
        },
        validate: {
            url: (value) => {
                if (!value) {
                    return "Required";
                }
                return null;
            },
        },
    });

    return (
        <form onSubmit={form.onSubmit(handleSubmit)}>
            <Stack maw={800} mx="auto" my="md">
                <LoadingOverlay visible={fetching} />
                <Text>Here you can give the url for a webpage you want to attach to the conversation.</Text>
                <TextInput label="URL" withAsterisk {...form.getInputProps("url")} />
                <Group justify="flex-end" mt="md">
                    <Button type="submit" disabled={fetching}>
                        Fetch site
                    </Button>
                </Group>
            </Stack>
        </form>
    );
}

export default AttachWebpageForm;
