import { useQuery } from "urql";
import { Box, Button, Group, Table, Badge, Pagination, LoadingOverlay, Text, Stack } from "@mantine/core";
import { useState } from "react";

import { documentPageQuery, type DocumentPageQuery, type DocumentPageQueryVariables } from "./queries";
import { documentTypeLabel, languageLabel } from "../utils";
import Loading from "../Loading";

interface IProps {
    attachKnowledge: (documentId: number) => Promise<void>;
    fetching: boolean;
}

function AttachKnowledgeTable({ fetching, attachKnowledge }: IProps): JSX.Element {
    const [page, setPage] = useState(1);
    const [result, execute] = useQuery<DocumentPageQuery, DocumentPageQueryVariables>({
        query: documentPageQuery,
        variables: {
            page,
        },
    });

    let main = null;
    if (result.fetching) {
        main = <Loading />;
    } else if (result.error) {
        main = <Box>Error: {result.error.message}</Box>;
    } else if (!result.data || result.data.documentPage.maxPage === 0) {
        main = <Box>No documents in database</Box>;
    } else {
        main = (
            <>
                <Pagination total={result.data.documentPage.maxPage} value={page} onChange={setPage} />
                <Table>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>Type</Table.Th>
                            <Table.Th>Name</Table.Th>
                            <Table.Th>Created</Table.Th>
                            <Table.Th>Tokens</Table.Th>
                            <Table.Th>Langauge</Table.Th>
                            <Table.Th>Tags</Table.Th>
                            <Table.Th colSpan={3} />
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {result.data.documentPage.items.map((document) => (
                            <Table.Tr key={document.id}>
                                <Table.Td>{documentTypeLabel(document.documentType)}</Table.Td>
                                <Table.Td>{document.name}</Table.Td>
                                <Table.Td>{new Date(document.created).toLocaleString("en-GB")}</Table.Td>
                                <Table.Td>{document.tokens}</Table.Td>
                                <Table.Td>{languageLabel(document.language)}</Table.Td>
                                <Table.Td>
                                    <Group gap="xs">
                                        {document.tags.map((tag) => (
                                            <Badge key={tag} size="xs">
                                                {tag}
                                            </Badge>
                                        ))}
                                    </Group>
                                </Table.Td>
                                <Table.Td>
                                    <Button onClick={() => attachKnowledge(document.id)}>Add document</Button>
                                </Table.Td>
                            </Table.Tr>
                        ))}
                    </Table.Tbody>
                </Table>
                <Pagination total={result.data.documentPage.maxPage} value={page} onChange={setPage} />
            </>
        );
    }

    return (
        <Stack maw={1000} mx="auto" my="md">
            <LoadingOverlay visible={fetching} />
            <Text>Here you can attach the content from a document in the Knowledge Database to the conversation.</Text>
            {main}
        </Stack>
    );
}

export default AttachKnowledgeTable;
