import { ActionIcon, Tooltip, type ActionIconProps } from "@mantine/core";
import type { MouseEventHandler, ReactNode } from "react";

interface IProps {
    onClick: MouseEventHandler<HTMLButtonElement>;
    label: string;
    icon: ReactNode;
    variant?: ActionIconProps["variant"];
    radius?: ActionIconProps["radius"];
    size?: ActionIconProps["size"];
    color?: ActionIconProps["color"];
    className?: ActionIconProps["className"];
    disabled?: boolean;
}

function ActionButton({
    onClick,
    label,
    icon,
    disabled,
    size,
    variant = "default",
    radius = "md",
    color,
    className,
}: IProps) {
    return (
        <ActionIcon
            variant={variant}
            aria-label={label}
            onClick={onClick}
            radius={radius}
            size={size}
            disabled={disabled}
            color={color}
            className={className}
        >
            <Tooltip label={label}>{icon}</Tooltip>
        </ActionIcon>
    );
}

export default ActionButton;
