import type { UseFormReturnType } from "@mantine/form";
import { Box, TagsInput as TagsInputOrig } from "@mantine/core";
import { useQuery } from "urql";

import { tasgQuery, type TagsQuery } from "./queries";

interface IHasTags {
    tags: string[];
}

interface IProps<T extends IHasTags> {
    form: UseFormReturnType<T>;
    label?: string;
    placeholder?: string;
}

function TagsInput<T extends IHasTags>({ form, placeholder, label = "Tags" }: IProps<T>): JSX.Element {
    const [result] = useQuery<TagsQuery>({ query: tasgQuery, requestPolicy: "network-only" });

    if (result.fetching) {
        return <Box component="label">Loading...</Box>;
    }
    if (result.error) {
        return <Box component="label">Error</Box>;
    }
    return (
        <TagsInputOrig
            label={label}
            placeholder={placeholder}
            data={result.data?.tags ?? []}
            clearable
            {...form.getInputProps("tags")}
        />
    );
}

export default TagsInput;
