import { Button, Tabs } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconBrowserPlus, IconFileUpload, IconNotes, IconTable } from "@tabler/icons-react";
import AttachFileForm, { type IFormValues as IAttachFileFormValues } from "./AttachFileForm";
import AttachKnowledgeTable from "./AttachKnowledgeTable";
import AttachWebpageForm, { type IFormValues as IAttachWebpageFormValues } from "./AttachWebpageForm";

interface IProps {
    attachFile: (values: IAttachFileFormValues) => Promise<void>;
    attachFileFetching: boolean;
    attachKnowledge: (documentId: number) => Promise<void>;
    attachKnowledgeFetching: boolean;
    attachWebpage: (values: IAttachWebpageFormValues) => Promise<void>;
    attachWebpageFetching: boolean;
}

function AttachModal({
    attachFile,
    attachFileFetching,
    attachKnowledge,
    attachKnowledgeFetching,
    attachWebpage,
    attachWebpageFetching,
}: IProps): JSX.Element {
    const handleClick = () => {
        modals.open({
            title: "Attach file to conversation",
            size: "1000px",
            children: (
                <>
                    <Tabs defaultValue="attachFile">
                        <Tabs.List>
                            <Tabs.Tab value="attachFile" leftSection={<IconFileUpload />}>
                                Upload file
                            </Tabs.Tab>
                            <Tabs.Tab value="attachWebpage" leftSection={<IconBrowserPlus />}>
                                Add Webpage
                            </Tabs.Tab>
                            <Tabs.Tab value="attachKnowledge" leftSection={<IconTable />}>
                                Add from Knowledge Database
                            </Tabs.Tab>
                        </Tabs.List>
                        <Tabs.Panel value="attachFile">
                            <AttachFileForm
                                handleSubmit={(values) => {
                                    modals.closeAll();
                                    attachFile(values);
                                }}
                                fetching={attachFileFetching}
                            />
                        </Tabs.Panel>
                        <Tabs.Panel value="attachKnowledge">
                            <AttachKnowledgeTable
                                attachKnowledge={async (id) => {
                                    modals.closeAll();
                                    attachKnowledge(id);
                                }}
                                fetching={attachKnowledgeFetching}
                            />
                        </Tabs.Panel>
                        <Tabs.Panel value="attachWebpage">
                            <AttachWebpageForm
                                handleSubmit={(values) => {
                                    modals.closeAll();
                                    attachWebpage(values);
                                }}
                                fetching={attachWebpageFetching}
                            />
                        </Tabs.Panel>
                    </Tabs>
                </>
            ),
        });
    };
    return (
        <Button onClick={handleClick} leftSection={<IconNotes />}>
            Attach data
        </Button>
    );
}

export default AttachModal;
