import { gql } from "urql";

// Current user query

export const currentUserQuery = gql`
    query CurrentUserQuery {
        userCurrent {
            id
        }
    }
`;

export type * from "./__generated__/current-user-query";

// Login mutation

export const loginMutation = gql`
    mutation LoginMutation($email:String!, $password:String!) {
        login(email:$email,password:$password) {
            __typename
            id
        }
    }
`;

export type * from "./__generated__/login-mutation";

// Tag query

export const tasgQuery = gql`
    query TagsQuery {
        tags
    }
`;

export type * from "./__generated__/tags-query";

// Site config query

export const siteConfigQuery = gql`
    query SiteConfigQuery {
        config {
            minPasswordLength
            chatModels
            imageModel
        }
    }
`;

export type * from "./__generated__/site-config-query";
