import { Box } from "@mantine/core";
import { useQuery } from "urql";
import { createContext, type ReactNode, type FC, type ReactElement, useContext } from "react";

import { siteConfigQuery, type SiteConfigQuery } from "./queries";
import Loading from "./Loading";

const SiteConfigContext = createContext<SiteConfigQuery["config"] | null>(null);
SiteConfigContext.displayName = "SiteConfigContext";

interface IProps {
    children: ReactNode;
}

export const SiteConfigProvider: FC<IProps> = ({ children }): ReactElement => {
    const [result] = useQuery<SiteConfigQuery>({ query: siteConfigQuery });

    if (result.fetching) {
        return <Loading />;
    }
    if (result.error) {
        return <Box>Error</Box>;
    }
    return <SiteConfigContext.Provider value={result.data?.config ?? null}>{children}</SiteConfigContext.Provider>;
};

export function useSiteConfig(): SiteConfigQuery["config"] {
    return useContext(SiteConfigContext)!;
}
