import { Box } from "@mantine/core";
import type { ReactNode } from "react";

interface IProps {
    show: boolean;
    children: ReactNode;
}

function Hider({ show, children }: IProps): JSX.Element {
    const display = show ? "block" : "none";
    return <Box display={display}>{children}</Box>;
}

export default Hider;
